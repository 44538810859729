import React from 'react';
import Button from 'react-bootstrap/Button';
import ModalFormWrapper from './ModalFormWrapper.js';
import ContentEditableBlock from './ContentEditableBlock.js';

class ArticleTextForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.prepopulateValue(this.props.articleElement);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    prepopulateValue(element) {
        if (element !== null) {
            return {content: element.content};
        } else {
            return {content: ''};
        }
    }

    handleChange(e) {
        this.setState({content: e.target.value});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.handleSubmit({content: this.state.content, kind: 'text'});
    }

    handleCancel(e) {
        this.props.handleCancel();
    }

    render() {
        return (
            <ModalFormWrapper
                handleCancel={this.handleCancel}
                handleSubmit={this.handleSubmit}
                title='Edit article text element'
                content={
                    <ContentEditableBlock
                        onChange={this.handleChange}
                        sanitizeText={this.props.sanitizeText}
                        contentHtml={this.state.content}
                    />
                }
                buttons={
                    <>
                        <Button variant='danger' onClick={this.handleCancel}>Cancel</Button>
                        <Button type='submit' variant='success'>Submit</Button>
                    </>
                }
            />
        );
    }
}

export default ArticleTextForm;
