import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormSelect from 'react-bootstrap/FormSelect';
import Button from 'react-bootstrap/Button';
import ModalFormWrapper from './ModalFormWrapper.js';

class MenuAddElementForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {kind: 'link', title: ''}
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        if (e.target.name === 'kind') {
            this.setState({kind: e.target.value});
        } else if (e.target.name === 'title') {
            this.setState({title: e.target.value});
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.handleSubmit({kind: this.state.kind, title: this.state.title});
    }

    handleCancel(e) {
        this.props.handleCancel();
    }

    render() {
        return (
            <ModalFormWrapper
                handleCancel={this.handleCancel}
                handleSubmit={this.handleSubmit}
                title='Add menu link or dropdown'
                content={
                    <>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text id='add-kind'>Kind</InputGroup.Text>
                            <FormSelect
                                aria-label='add-kind'
                                aria-describedby='add-kind'
                                name='kind'
                                value={this.state.kind}
                                onChange={this.handleChange}
                            >
                                <option value='link'>Link</option>
                                <option value='dropdown'>Dropdown</option>
                            </FormSelect>
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text id='add-title'>Title</InputGroup.Text>
                            <FormControl
                                type='text'
                                aria-describedby='add-title'
                                name='title'
                                value={this.state.title}
                                onChange={this.handleChange}
                            />
                        </InputGroup>
                    </>
                }
                buttons={
                    <>
                        <Button variant='danger' onClick={this.handleCancel}>Cancel</Button>
                        <Button type='submit' variant='success'>Add</Button>
                    </>
                }
            />
        );
    }
}

export default MenuAddElementForm;
