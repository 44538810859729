import React from 'react';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import ModalFormWrapper from './ModalFormWrapper.js';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {username: '', password: ''}
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        if (e.target.name === 'username') {
            this.setState({username: e.target.value});
        } else if (e.target.name === 'password') {
            this.setState({password: e.target.value});
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.handleSubmit({username: this.state.username, password: this.state.password});
    }

    handleCancel(e) {
        this.props.handleCancel();
    }

    render() {
        const loginError = this.props.error ? <Alert variant='danger'>{this.props.error}</Alert> : null;

        return (
            <ModalFormWrapper
                handleCancel={this.handleCancel}
                handleSubmit={this.handleSubmit}
                title='Login form'
                content={
                    <>
                        {loginError}
                        <InputGroup className='mb-3'>
                            <InputGroup.Text id='login-username'>Username</InputGroup.Text>
                            <FormControl
                                type='text'
                                aria-describedby='login-username'
                                name='username'
                                value={this.state.username}
                                onChange={this.handleChange}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text id='login-password'>Password</InputGroup.Text>
                            <FormControl
                                type='password'
                                aria-describedby='login-password'
                                name='password'
                                value={this.state.password}
                                onChange={this.handleChange}
                            />
                        </InputGroup>
                    </>
                }
                buttons={
                    <>
                        <Button variant='danger' onClick={this.handleCancel}>Cancel</Button>
                        <Button type='submit' variant='success'>Login</Button>
                    </>
                }
            />
        );
    }
}

export default LoginForm;
