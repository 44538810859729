import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import MenuRows from './MenuRows.js';
import MovableBlock from '../form/MovableBlock.js';
import TooltipButtons from '../form/TooltipButtons.js';
import './MenuDropdown.scss';

class MenuDropdown extends React.Component {
    render () {
        const defaultActiveKey = this.props.movingElement !== null && this.props.movingElement.id !== this.props.elementId ? '0' : null;
        const dropdown = (
            <Accordion defaultActiveKey={defaultActiveKey}>
                <Accordion.Item className='border-0' eventKey='0'>
                    <Accordion.Button className='menu-text-fancy'>{this.props.title}</Accordion.Button>
                    <Accordion.Body className='w-100 p-0 dropdown-smaller-text'>
                        <MenuRows
                            elements={this.props.elements}
                            elementEdit={this.props.elementEdit}
                            elementMove={this.props.elementMove}
                            movingElement={this.props.movingElement}
                            handleDragging={this.props.handleDragging}
                            submitMove={this.props.submitMove}
                            elementDelete={this.props.elementDelete}
                            loggedIn={this.props.loggedIn}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );

        if (!this.props.loggedIn) {
            return dropdown;
        }

        if (this.props.movingElement) {
            return (
                <>
                    <MovableBlock
                        movingElement={this.props.movingElement}
                        handleDragging={this.props.handleDragging}
                        submitMove={this.props.submitMove}
                        elementId={'before_' + this.props.elementId}
                        content={<div className='text-center'>&nbsp;</div>}
                    />
                    <MovableBlock
                        movingElement={this.props.movingElement}
                        handleDragging={this.props.handleDragging}
                        submitMove={this.props.submitMove}
                        elementId={this.props.elementId}
                        content={dropdown}
                    />
                    <MovableBlock
                        movingElement={this.props.movingElement}
                        handleDragging={this.props.handleDragging}
                        submitMove={this.props.submitMove}
                        elementId={'after_' + this.props.elementId}
                        content={<div className='text-center'>&nbsp;</div>}
                    />
                </>
            );
        } else {
            return (
                <TooltipButtons
                    elementEdit={this.props.elementEdit}
                    elementMove={this.props.elementMove}
                    elementDelete={this.props.elementDelete}
                    elementId={this.props.elementId}
                    content={dropdown}
                />
            );
        }
    }
}

export default MenuDropdown;
