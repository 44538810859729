import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import './TooltipButtons.scss';

class TooltipButtons extends React.Component {
    render() {
        let elementEdit = null;
        if (this.props.elementEdit !== undefined) {
            if (this.props.elementEditKind !== undefined) {
                elementEdit = (
                    <Dropdown.Item
                        onClick={e => this.props.elementEdit(this.props.elementEditKind, this.props.elementId)}
                        as='button'
                    ><span className='text-info'>&#9733;</span> edit</Dropdown.Item>
                );
            } else {
                elementEdit = <Dropdown.Item onClick={e => this.props.elementEdit(this.props.elementId)} as='button'><span className='text-info'>&#9733;</span> edit</Dropdown.Item>;
            }
        }
        const className = this.props.className !== undefined ? 'tooltip-wrapper ' + this.props.className : 'tooltip-wrapper';

        return (
            <div className={className}>
                {this.props.content}
                <Dropdown className='tooltip-buttons'>
                    <DropdownButton variant='secondary' title='' align='end' size='sm'>
                        {elementEdit}
                        <Dropdown.Item onClick={e => this.props.elementMove(this.props.elementId)} as='button'><span className='text-warning'>&#8597;</span> move</Dropdown.Item>
                        <Dropdown.Item onClick={e => this.props.elementDelete(this.props.elementId)} as='button'><span className='text-danger'>&#10007;</span> delete</Dropdown.Item>
                    </DropdownButton>
                </Dropdown>
            </div>
        );
    }
}

export default TooltipButtons;
