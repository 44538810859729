import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';

class Progress extends React.Component
{
    render() {
        return (
            <Modal
                show={true}
                onHide={this.props.handleCancel}
                backdrop='static'
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Please wait</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProgressBar animated variant="info" now={this.props.progress} />
                </Modal.Body>
            </Modal>
        );
    }
}

export default Progress;
