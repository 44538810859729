import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class Confirmation extends React.Component
{
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.handleYes();
    }

    render() {
        return (
            <Modal
                show={true}
                onHide={this.props.handleNo}
                backdrop='static'
                keyboard={false}
            >
                <Form onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation required!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.text}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='danger' onClick={this.props.handleNo}>No</Button>
                        <Button type='submit' variant='success'>Yes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

export default Confirmation;
