import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

class ModalFormWrapper extends React.Component {
    render() {
        return (
            <Modal
                show={true}
                onHide={this.props.handleCancel}
                backdrop='static'
                keyboard={false}
                size='lg'
            >
                <Form onSubmit={this.props.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='editor-body'>
                        {this.props.content}
                    </Modal.Body>
                    <Modal.Footer>
                        {this.props.buttons}
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

export default ModalFormWrapper;
