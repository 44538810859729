import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ModalFormWrapper from './ModalFormWrapper.js';
import Table from 'react-bootstrap/Table';

class StorageFileForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getFormElementsIdKeyed();
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePaginationPrevious = this.handlePaginationPrevious.bind(this);
        this.handlePaginationNext = this.handlePaginationNext.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentPage !== this.props.currentPage) {
            this.setState(this.getFormElementsIdKeyed());
        }
    }

    getFormElementsIdKeyed() {
        let elements = {}
        this.props.elements.forEach(element => {
            elements[element.id] = {...element, ...{marked_for_delete: false}};
        });

        return {elements: elements};
    }

    handleChange(e) {
        if (String(e.target.name).startsWith('delete_')) {
            const id = e.target.name.replace('delete_', '');
            this.setState(prevState => ({elements: {...prevState.elements, ...{[id]: {...prevState.elements[id], ...{marked_for_delete: e.target.checked}}}}}));
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        let ids_to_delete = [];
        Object.entries(this.state.elements).forEach(([id, element]) => {
            if (element.marked_for_delete) {
                ids_to_delete.push(element.id);
            }
        });
        this.props.handleSubmit(ids_to_delete);
    }

    handleCancel(e) {
        this.props.handleCancel();
    }

    handlePaginationNext() {
        this.props.handlePagination(this.props.currentPage + 1)
    }

    handlePaginationPrevious() {
        if (1 < this.props.currentPage) {
            this.props.handlePagination(this.props.currentPage - 1)
        }
    }

    render() {
        const fields = Object.entries(this.state.elements).map(([id, element]) => {
            return (
                <tr key={'delete_' + element.id}>
                    <td>
                        <Form.Check
                            inline
                            checked={this.state.elements[element.id].marked_for_delete}
                            onChange={this.handleChange}
                            label=' '
                            name={'delete_' + element.id}
                            id={'delete_' + element.id}
                        />
                    </td>
                    <td>
                        <span>{element.title}</span>
                    </td>
                    <td>
                        <a href={element.slug} target='_blank' rel='noreferrer'>open</a>
                    </td>
                </tr>
            );
        });

        return (
            <ModalFormWrapper
                handleCancel={this.handleCancel}
                handleSubmit={this.handleSubmit}
                title='Remove files from storage'
                content={
                    <Table striped bordered hover responsive size='sm'>
                        <thead>
                            <tr>
                                <th>delete</th>
                                <th>name</th>
                                <th>preview</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fields}
                        </tbody>
                    </Table>
                }
                buttons={
                    <>
                        <Button variant='secondary' disabled>Current page {this.props.currentPage}</Button>
                        <div>
                            <Button className='m-1' variant='success' onClick={this.handlePaginationPrevious}>Previous page</Button>
                            <Button className='m-1' variant='success' onClick={this.handlePaginationNext}>Next page</Button>
                        </div>
                        <div>
                            <Button className='m-1' variant='danger' onClick={this.handleCancel}>Cancel</Button>
                            <Button className='m-1' type='submit' variant='success'>Delete selected</Button>
                        </div>
                    </>
                }
            />
        );
    }
}

export default StorageFileForm;
