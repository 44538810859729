import React from 'react';
import ArticleText from './ArticleText.js';
import ArticleLink from './ArticleLink.js';
import ArticleImage from './ArticleImage.js';
import ArticleFile from './ArticleFile.js';

class ArticleElements extends React.Component {
    render() {
        let elements = [];
        this.props.elements.forEach((element, index) => {
            switch (element.kind) {
                case 'text':
                    elements.push(
                        <ArticleText
                            key={element.id}
                            content={this.props.sanitizeText(element.content)}
                            loggedIn={this.props.loggedIn}
                            elementEdit={this.props.elementEdit}
                            elementMove={this.props.elementMove}
                            movingElement={this.props.movingElement}
                            handleDragging={this.props.handleDragging}
                            submitMove={this.props.submitMove}
                            elementDelete={this.props.elementDelete}
                            elementId={element.id}
                        />
                    );
                    break;
                case 'link':
                    elements.push(
                        <ArticleLink
                            key={element.id}
                            content={element.content}
                            loggedIn={this.props.loggedIn}
                            elementEdit={this.props.elementEdit}
                            elementMove={this.props.elementMove}
                            movingElement={this.props.movingElement}
                            handleDragging={this.props.handleDragging}
                            submitMove={this.props.submitMove}
                            elementDelete={this.props.elementDelete}
                            elementId={element.id}
                        />
                    );
                    break;
                case 'image':
                    let imageContainerClass = 'article-image-container-wide';
                    if (
                        (typeof this.props.elements[index - 1] !== 'undefined' && this.props.elements[index - 1].kind === 'image') ||
                        (typeof this.props.elements[index + 1] !== 'undefined' && this.props.elements[index + 1].kind === 'image')
                    ) {
                        imageContainerClass = '';
                    }
                    elements.push(
                        <ArticleImage
                            key={element.id}
                            content={element.content}
                            loggedIn={this.props.loggedIn}
                            elementMove={this.props.elementMove}
                            movingElement={this.props.movingElement}
                            handleDragging={this.props.handleDragging}
                            submitMove={this.props.submitMove}
                            elementDelete={this.props.elementDelete}
                            elementId={element.id}
                            imageView={this.props.imageView}
                            imageContainerClass={imageContainerClass}
                        />
                    );
                    break;
                case 'file':
                    elements.push(
                        <ArticleFile
                            key={element.id}
                            content={element.content}
                            loggedIn={this.props.loggedIn}
                            elementMove={this.props.elementMove}
                            movingElement={this.props.movingElement}
                            handleDragging={this.props.handleDragging}
                            submitMove={this.props.submitMove}
                            elementDelete={this.props.elementDelete}
                            elementId={element.id}
                        />
                    );
                    break;
                default:
                    break;
            }
        });

        return (
            <>
                {elements}
            </>
        )
    }
}

export default ArticleElements;
