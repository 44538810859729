import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ModalFormWrapper from './ModalFormWrapper.js';
import Table from 'react-bootstrap/Table';

class ArchivedArticlesForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getSlugs();
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePaginationPrevious = this.handlePaginationPrevious.bind(this);
        this.handlePaginationNext = this.handlePaginationNext.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentPage !== this.props.currentPage) {
            this.setState(this.getSlugs());
        }
    }

    getSlugs() {
        let slugs = {}
        this.props.elements.forEach(value => {
            slugs[value] = {slug: value, marked_for_delete: false, marked_for_restore: false};
        });

        return {slugs: slugs};
    }

    handleChange(e) {
        if (String(e.target.name).startsWith('delete_')) {
            const slug = e.target.name.replace('delete_', '');
            this.setState(prevState => ({slugs: {...prevState.slugs, ...{[slug]: {slug: slug, marked_for_delete: e.target.checked, marked_for_restore: false}}}}));
        } else if (String(e.target.name).startsWith('restore_')) {
            const slug = e.target.name.replace('restore_', '');
            this.setState(prevState => ({slugs: {...prevState.slugs, ...{[slug]: {slug: slug, marked_for_delete: false, marked_for_restore: e.target.checked}}}}));
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        let slugs_to_delete = [];
        let slugs_to_restore = [];
        Object.entries(this.state.slugs).forEach(([slug, values]) => {
            if (values.marked_for_delete) {
                slugs_to_delete.push(values.slug);
            } else if (values.marked_for_restore) {
                slugs_to_restore.push(values.slug);
            }
        });
        this.props.handleSubmit(slugs_to_delete, slugs_to_restore);
    }

    handleCancel(e) {
        this.props.handleCancel();
    }

    handlePaginationNext() {
        this.props.handlePagination(this.props.currentPage + 1)
    }

    handlePaginationPrevious() {
        if (1 < this.props.currentPage) {
            this.props.handlePagination(this.props.currentPage - 1)
        }
    }

    render() {
        const fields = Object.entries(this.state.slugs).map(([slug, element]) => {
            return (
                <tr key={'delete_' + element.slug}>
                    <td>
                        <Form.Check
                            inline
                            checked={this.state.slugs[element.slug].marked_for_delete}
                            onChange={this.handleChange}
                            label=' '
                            name={'delete_' + element.slug}
                            id={'delete_' + element.slug}
                        />
                    </td>
                    <td>
                        <Form.Check
                            inline
                            checked={this.state.slugs[element.slug].marked_for_restore}
                            onChange={this.handleChange}
                            label=' '
                            name={'restore_' + element.slug}
                            id={'restore_' + element.slug}
                        />
                    </td>
                    <td>
                        <span>{element.slug}</span>
                    </td>
                </tr>
            );
        });

        return (
            <ModalFormWrapper
                handleCancel={this.handleCancel}
                handleSubmit={this.handleSubmit}
                title='Remove files from storage'
                content={
                    <Table striped bordered hover responsive size='sm'>
                        <thead>
                            <tr>
                                <th>delete</th>
                                <th>restore</th>
                                <th>slug</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fields}
                        </tbody>
                    </Table>
                }
                buttons={
                    <>
                        <Button variant='secondary' disabled>Current page {this.props.currentPage}</Button>
                        <div>
                            <Button className='m-1' variant='success' onClick={this.handlePaginationPrevious}>Previous page</Button>
                            <Button className='m-1' variant='success' onClick={this.handlePaginationNext}>Next page</Button>
                        </div>
                        <div>
                            <Button className='m-1' variant='danger' onClick={this.handleCancel}>Cancel</Button>
                            <Button className='m-1' type='submit' variant='success'>Submit selected actions</Button>
                        </div>
                    </>
                }
            />
        );
    }
}

export default ArchivedArticlesForm;
