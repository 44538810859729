import React from 'react';
import MovableBlock from '../form/MovableBlock.js';
import TooltipButtons from '../form/TooltipButtons.js';
import './ArticleFile.scss';

class ArticleFile extends React.Component {
    render() {
        const file = (
            <div className='article-block file-text-left'>
                <a className='text-secondary' target='_blank' rel='noreferrer' href={this.props.content.slug}>{this.props.content.title}</a>
            </div>
        );

        if (!this.props.loggedIn) {
            return file;
        }

        if (this.props.movingElement !== null) {
            return (
                <MovableBlock
                    movingElement={this.props.movingElement}
                    handleDragging={this.props.handleDragging}
                    submitMove={this.props.submitMove}
                    elementId={this.props.elementId}
                    content={file}
                />
            );
        } else {
            return (
                <TooltipButtons
                    elementMove={this.props.elementMove}
                    elementDelete={this.props.elementDelete}
                    elementId={this.props.elementId}
                    content={file}
                />
            );
        }
    }
}

export default ArticleFile;
