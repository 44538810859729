import React from 'react';
import './ArticleText.scss';
import MovableBlock from '../form/MovableBlock.js';
import TooltipButtons from '../form/TooltipButtons.js';

class ArticleText extends React.Component {
    render() {
        const text = (
            <div className='article-block article-text-div' dangerouslySetInnerHTML={{__html: this.props.content}} />
        )

        if (!this.props.loggedIn) {
            return text;
        }

        if (this.props.movingElement !== null) {
            return (
                <MovableBlock
                    movingElement={this.props.movingElement}
                    handleDragging={this.props.handleDragging}
                    submitMove={this.props.submitMove}
                    elementId={this.props.elementId}
                    content={text}
                />
            );
        } else {
            return (
                <TooltipButtons
                    elementEdit={this.props.elementEdit}
                    elementMove={this.props.elementMove}
                    elementDelete={this.props.elementDelete}
                    elementId={this.props.elementId}
                    elementEditKind='text'
                    content={text}
                />
            );
        }
    }
}

export default ArticleText;
