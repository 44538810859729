import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import ModalFormWrapper from './ModalFormWrapper.js';

class StorageUploadForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {file: '', title: ''};
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        if (e.target.name === 'file' && e.target.files.length) {
            this.setState({file: e.target.files[0], title: e.target.files[0].name.replace('.', ' ')});
        } else if (e.target.name === 'title') {
            this.setState({title: e.target.value});
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.handleSubmit({file: this.state.file, title: this.state.title});
    }

    handleCancel(e) {
        this.props.handleCancel();
    }

    render() {
        return (
            <ModalFormWrapper
                handleCancel={this.handleCancel}
                handleSubmit={this.handleSubmit}
                title='Upload file or image to your storage'
                content={
                    <>
                        <Form.Group className='mb-3'>
                            <Form.Control
                                type='file'
                                name='file'
                                onChange={e => this.handleChange(e, 'file')}
                            />
                        </Form.Group>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text id='add-title'>Title</InputGroup.Text>
                            <FormControl
                                type='text'
                                aria-describedby='add-title'
                                name='title'
                                value={this.state.title}
                                onChange={this.handleChange}
                            />
                        </InputGroup>
                    </>
                }
                buttons={
                    <>
                        <Button variant='danger' onClick={this.handleCancel}>Cancel</Button>
                        <Button type='submit' variant='success'>Add</Button>
                    </>
                }
            />
        );
    }
}

export default StorageUploadForm;
