import React from 'react';
import Image from 'react-bootstrap/Image';
import MovableBlock from '../form/MovableBlock.js';
import TooltipButtons from '../form/TooltipButtons.js';

class MenuImage extends React.Component {
    render() {
        const image = (
            <div className='menu-image-container w-100 py-2'>
                <div className='menu-image-holder text-center'>
                    <Image className='w-100' src={this.props.content.slug} alt={this.props.content.title} />
                </div>
            </div>
        );

        if (!this.props.loggedIn) {
            return image;
        }

        if (this.props.movingElement !== null) {
            return (
                <MovableBlock
                    movingElement={this.props.movingElement}
                    handleDragging={this.props.handleDragging}
                    submitMove={this.props.submitMove}
                    elementId={this.props.elementId}
                    content={image}
                />
            );
        } else {
            return (
                <TooltipButtons
                    elementMove={this.props.elementMove}
                    elementDelete={this.props.elementDelete}
                    elementId={this.props.elementId}
                    content={image}
                />
            );
        }
    }
}

export default MenuImage;
