import React from 'react';
import Nav from 'react-bootstrap/Nav';
import {NavLink} from 'react-router-dom';
import MovableBlock from '../form/MovableBlock.js';
import TooltipButtons from '../form/TooltipButtons.js';

class MenuLink extends React.Component {
    render () {
        if (this.props.slug === undefined) {
            return null;
        }
        const link = (
            <Nav.Link
                exact={true}
                to={this.props.slug}
                as={NavLink}
                className='text-center px-0 py-2 menu-text-fancy'
            >{this.props.title}</Nav.Link>
        );

        if (!this.props.loggedIn) {
            return link;
        }
        if (this.props.movingElement !== null) {
            return (
                <MovableBlock
                    movingElement={this.props.movingElement}
                    handleDragging={this.props.handleDragging}
                    submitMove={this.props.submitMove}
                    elementId={this.props.elementId}
                    content={link}
                />
            );
        } else {
            return (
                <TooltipButtons
                    elementEdit={this.props.elementEdit}
                    elementMove={this.props.elementMove}
                    elementDelete={this.props.elementDelete}
                    elementId={this.props.elementId}
                    content={link}
                />
            );
        }
    }
}

export default MenuLink;
