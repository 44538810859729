import React from 'react';
import Button from 'react-bootstrap/Button';
import './MovableBlock.scss';

class MovableBlock extends React.Component
{
    constructor(props) {
        super(props);
        this.handleDragging = this.handleDragging.bind(this);
        this.submitMove = this.submitMove.bind(this);
    }

    handleDragging() {
        this.props.handleDragging(this.props.elementId);
    }

    submitMove() {
        this.props.submitMove(this.props.elementId);
    }

    render() {
        const elementClass = this.props.className ? 'position-relative ' + this.props.className : 'position-relative d-block';
        const movingTag = this.props.elementId === this.props.movingElement.id ? <span className='text-warning'>&#8597;</span> : null;
        let buttonClasses = String(this.props.elementId).startsWith('before_') || String(this.props.elementId).startsWith('after_') ? (
            'movable-block-container border-0 text-end'
        ) : 'movable-block-container border-warning text-end';
        buttonClasses += this.props.elementId === this.props.movingElement.id ? ' bg-warning-transparent' : ' bg-transparent';

        return (
            <div className={elementClass}>
                <Button
                    className={buttonClasses}
                    onClick={this.submitMove}
                    onMouseOver={this.handleDragging}
                    draggable='false'
                >{movingTag}</Button>
                {this.props.content}
            </div>
        );
    }
}

export default MovableBlock;
