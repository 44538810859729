import React from 'react';
import Image from 'react-bootstrap/Image';
import MovableBlock from '../form/MovableBlock.js';
import TooltipButtons from '../form/TooltipButtons.js';
import './ArticleImage.scss';

class ArticleImage extends React.Component {
    constructor(props) {
        super(props);
        this.openImageViewer = this.openImageViewer.bind(this);
    }

    openImageViewer() {
        this.props.imageView(this.props.elementId, 0);
    }

    render() {
        const imageClassAddition = !this.props.loggedIn ? this.props.imageContainerClass : '';
        const image = (
            <div className={'article-image-container ' + imageClassAddition}>
                <div className='article-image-holder'>
                    <Image
                        src={this.props.content.slug}
                        alt={this.props.content.title}
                        onClick={this.openImageViewer}
                    />
                </div>
            </div>
        );

        if (!this.props.loggedIn) {
            return image;
        }

        if (this.props.movingElement !== null) {
            return (
                <MovableBlock
                    className={'d-inline-block ' + this.props.imageContainerClass}
                    movingElement={this.props.movingElement}
                    handleDragging={this.props.handleDragging}
                    submitMove={this.props.submitMove}
                    elementId={this.props.elementId}
                    content={image}
                />
            );
        } else {
            return (
                <TooltipButtons
                    className={'d-inline-block ' + this.props.imageContainerClass}
                    elementMove={this.props.elementMove}
                    elementDelete={this.props.elementDelete}
                    elementId={this.props.elementId}
                    content={image}
                />
            );
        }
    }
}

export default ArticleImage;
