import React from 'react';
import Button from 'react-bootstrap/Button';
import './ContentEditableBlock.scss';
import Image from 'react-bootstrap/Image';
import CleanAllIcon from '../images/clean_all.png'; 
import CleanIcon from '../images/clean.png'; 
import UndoIcon from '../images/undo.png'; 
import BoldIcon from '../images/bold.png'; 
import HeaderIcon from '../images/header.png'; 
import SmallIcon from '../images/small.png'; 
import JustifyIcon from '../images/justify.png'; 
import CenterIcon from '../images/center.png'; 
import PointedListIcon from '../images/pointed_list.png';
import NumberedListIcon from '../images/numbered_list.png';


class ContentEditableBlock extends React.Component
{
    constructor(props) {
        super(props);
        this.emitChange = this.emitChange.bind(this);
        this.selectedHeader = this.selectedHeader.bind(this);
        this.selectedBold = this.selectedBold.bind(this);
        this.selectedSmall = this.selectedSmall.bind(this);
        this.selectedClean = this.selectedClean.bind(this);
        this.selectedCenter = this.selectedCenter.bind(this);
        this.selectedLeft = this.selectedLeft.bind(this);
        this.startListWithoutNumbers = this.startListWithoutNumbers.bind(this);
        this.startListWithNumbers = this.startListWithNumbers.bind(this);
        this.getListNode = this.getListNode.bind(this);
        this.cleanText = this.cleanText.bind(this);
        this.undoText = this.undoText.bind(this);
        this.addHistory = this.addHistory.bind(this);
        this.wrapSelectedInNode = this.wrapSelectedInNode.bind(this);
        this.shouldRefresh = false;
        this.lastContent = [];
        this.editableBlockRef = React.createRef();
        this.historyLimit = 10;
    }

    shouldComponentUpdate(nextProps) {
        return this.shouldRefresh;
    }

    componentDidUpdate() {
        let range = document.createRange();
        range.selectNodeContents(this.editableBlockRef.current);
        range.collapse(false);
        let selection = document.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        this.editableBlockRef.current.key = Math.random();
    }

    emitChange(e, forceRefresh, skipHistoryPush) {
        if (!skipHistoryPush && (!e.nativeEvent || 'input' === e.nativeEvent.type)) {
            this.addHistory(e);
        }
        this.shouldRefresh = false;
        const contetnHtml = e.target.innerHTML;
        const sanitizedHtml = this.props.sanitizeText(contetnHtml);
        if (contetnHtml !== sanitizedHtml) {
            this.shouldRefresh = true;
        } else if (forceRefresh) {
            this.shouldRefresh = true;
        }
        this.props.onChange({
            target: {
                value: contetnHtml
            }
        });
    }

    selectedHeader() {
        this.wrapSelectedInNode('h1');
    }

    selectedBold() {
        this.wrapSelectedInNode('b');
    }

    selectedSmall() {
        this.wrapSelectedInNode('small');
    }

    selectedClean() {
        this.wrapSelectedInNode('span');
    }

    selectedLeft() {
        this.wrapSelectedInNode('div');
    }

    selectedCenter() {
        this.wrapSelectedInNode('p');
    }

    startListWithoutNumbers() {
        this.wrapSelectedInNode('ul');
    }

    startListWithNumbers() {
        this.wrapSelectedInNode('ol');
    }

    getListNode(ordered, fragmentContent) {
        let newNode = document.createElement(ordered ? 'ol' : 'ul');
        let newNestedNode = document.createElement('li');
        newNestedNode.appendChild(document.createTextNode(fragmentContent));
        newNode.appendChild(newNestedNode);

        return newNode;
    }

    isInContentEditable(node) {
        if (node !== null && node.id === 'content-editable-block-id') {
            return true;
        } else if (node !== null && node.parentNode !== undefined) {
            return this.isInContentEditable(node.parentNode);
        }

        return false
    }

    selectParentNonSpan(node) {
        if (node.parentNode.id === 'content-editable-block-id') {
            return node;
        } else if (node.tagName.toLowerCase() === 'span') {
            return this.selectParentNonSpan(node.parentNode);
        }

        return node;
    }

    wrapSelectedInNode(tag) {
        this.addHistory();
        const selection = document.getSelection();
        if (!selection || selection.rangeCount < 1 || !this.isInContentEditable(selection.baseNode)) {
            return;
        }

        let range = selection.getRangeAt(0);
        if (tag === 'span') {
            if (range.commonAncestorContainer.nodeType !== 1) {
                let node = this.selectParentNonSpan(range.commonAncestorContainer.parentNode);
                if (node && node.nodeType === 1) {
                    range.selectNode(node);
                }
            }
        }
        range = range.cloneRange();
        let fragmentContent = range.extractContents();
        let wrapperDiv = document.createElement('div')
        wrapperDiv.appendChild(fragmentContent);
        fragmentContent = wrapperDiv.innerHTML;
        fragmentContent = fragmentContent.replace(/(&nbsp;)+/g, ' ');
        fragmentContent = fragmentContent.replace(/(&amp;)+/g, ' ');
        if (['p', 'div'].includes(tag)) {
            const to = tag;
            const from = 'p' === tag ? 'div' : 'p';
            fragmentContent = fragmentContent.replace((new RegExp('<' + from + '>', 'g')), '<' + to + '>');
            fragmentContent = fragmentContent.replace((new RegExp('</' + from + '>', 'g')), '</' + to + '>');
        } else {
            fragmentContent = fragmentContent.replace(/(<([^>]+)>)/ig, ' ').replace(/( )+/g, ' ');;
        }

        let newNode;
        if ('ol' === tag || 'ul' === tag) {
            newNode = this.getListNode('ol' === tag, fragmentContent);
        } else if ('' === fragmentContent.textContent) {
            return;
        } else {
            newNode = document.createElement(tag);
            newNode.appendChild(document.createTextNode(fragmentContent));
        }
        range.insertNode(newNode);
        this.emitChange({
            target: {innerHTML: this.editableBlockRef.current.innerHTML}
        }, false, true);
    }

    cleanText() {
        const cleanedText = this.editableBlockRef.current.innerHTML.replace(/(<([^>]+)>)/ig, ' ').replace(/(&nbsp;| )+/g, ' ');
        this.emitChange({
            target: {innerHTML: cleanedText}
        }, true);
    }

    undoText() {
        if (this.lastContent.length >= this.historyLimit) {
            this.lastContent.pop();
        }
        if (this.lastContent.length) {
            this.emitChange({
                target: {innerHTML: this.lastContent.pop()}
            }, true, true);
        }
    }

    addHistory() {
        if (this.lastContent.length >= this.historyLimit) {
            this.lastContent.shift();
        }
        this.lastContent.push(this.editableBlockRef.current.innerHTML);
    }

    render() {
        return (
            <>
                <div>
                    <Button variant='light' className='m-1' onClick={this.cleanText}>
                        <Image className='text-editor-icon' src={CleanAllIcon} alt='clean all'/>
                    </Button>
                    <Button variant='light' className='m-1' onClick={this.selectedClean}>
                        <Image className='text-editor-icon' src={CleanIcon} alt='clean'/>
                    </Button>
                    <Button variant='light' className='m-1' onClick={this.undoText}>
                        <Image className='text-editor-icon' src={UndoIcon} alt='undo'/>
                    </Button>
                    <Button variant='light' className='m-1' onClick={this.selectedHeader} title='convert selected text to header'>
                        <Image className='text-editor-icon' src={HeaderIcon} alt='header'/>
                    </Button>
                    <Button variant='light' className='m-1' onClick={this.selectedBold} title='make selected text bold'>
                        <Image className='text-editor-icon' src={BoldIcon} alt='bold'/>
                    </Button>
                    <Button variant='light' className='m-1' onClick={this.selectedSmall} title='make selected text smaller'>
                        <Image className='text-editor-icon' src={SmallIcon} alt='small'/>
                    </Button>
                    <Button variant='light' className='m-1' onClick={this.selectedLeft} title='move selected text to left'>
                        <Image className='text-editor-icon' src={JustifyIcon} alt='left'/>
                    </Button>
                    <Button variant='light' className='m-1' onClick={this.selectedCenter} title='move selected text to center'>
                        <Image className='text-editor-icon' src={CenterIcon} alt='center'/>
                    </Button>
                    <Button variant='light' className='m-1' onClick={this.startListWithoutNumbers} title='add pointed list'>
                        <Image className='text-editor-icon' src={PointedListIcon} alt='pointed list'/>
                    </Button>
                    <Button variant='light' className='m-1' onClick={this.startListWithNumbers} title='add ordered list'>
                        <Image className='text-editor-icon' src={NumberedListIcon} alt='numbered list'/>
                    </Button>
                </div>
                <div className='content-editable-block-container'>
                    <div
                        key='1'
                        id='content-editable-block-id'
                        ref={this.editableBlockRef}
                        className='content-editable-block'
                        onInput={this.emitChange}
                        onBlur={this.emitChange}
                        contentEditable='true'
                        dangerouslySetInnerHTML={{__html: this.props.contentHtml.length > 0 ? this.props.sanitizeText(this.props.contentHtml) : ' '}}
                    ></div>
                </div>
            </>
        );
    }
}

export default ContentEditableBlock;
