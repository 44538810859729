import React from 'react';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import MenuRows from './MenuRows.js';
import './MenuLink.scss';
import MenuAddElementForm from '../form/MenuAddElementForm.js';
import MenuEditElementForm from '../form/MenuEditElementForm.js';
import MenuAddImageForm from '../form/MenuAddImageForm.js';
import Confirmation from '../form/Confirmation.js';
import Info from '../form/Info.js';
import Progress from '../form/Progress.js';
import StorageUploadForm from '../form/StorageUploadForm.js';
import StorageImageForm from '../form/StorageImageForm.js';
import StorageFileForm from '../form/StorageFileForm.js';
import ArchivedArticlesForm from '../form/ArchivedArticlesForm.js';

/*
This component calls api for menu elements and calls this.props.updateRoutes with results
api is expected to return data for menu elements in the following format (data key of the response):
[
  {"id":1,"kind":"image","content":"{\"id\":1}","left":1,"right":1},
  {"id":2,"kind":"dropdown","content":"{\"title\":\"some dropdown\"}","left":2,"right":4},
  {"id":3,"kind":"link","slug":"/somelink","content":"{\"title\":\"some link\"}}","left":3,"right":3}
]
id - needed for edits and identification
kind - recognized are link, dropdown and image
content - depending on kind it will need title (for all) or slug (for link) or id (for image to call for details)
slug - required by links and used to match with corresponding articles
left - in combination with right allows to set ranges for dropdowns and elements positions, note that data should be sorted by left for proper nesting
*/

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...{menuElements: []},
            ...this.getCleanMenuChangesState()
        };

        this.closeMenuChanges = this.closeMenuChanges.bind(this);
        this.closeInfo = this.closeInfo.bind(this);
        this.cancelProgress = this.cancelProgress.bind(this);

        this.openMenuElementForm = this.openMenuElementForm.bind(this);
        this.openMenuImageForm = this.openMenuImageForm.bind(this);
        this.submitMenuElementOrImage = this.submitMenuElementOrImage.bind(this);

        this.openMenuMoveForm = this.openMenuMoveForm.bind(this);
        this.handleMenuDragging = this.handleMenuDragging.bind(this);
        this.submitMenuMove = this.submitMenuMove.bind(this);

        this.openMenuDeleteForm = this.openMenuDeleteForm.bind(this);
        this.submitMenuDelete = this.submitMenuDelete.bind(this);

        this.openStorageUploadForm= this.openStorageUploadForm.bind(this);
        this.uploadFile = this.uploadFile.bind(this);

        this.openStorageImageForm = this.openStorageImageForm.bind(this);
        this.openStorageFileForm = this.openStorageFileForm.bind(this);
        this.submitStorage = this.submitStorage.bind(this);

        this.openArchivedArticlesForm = this.openArchivedArticlesForm.bind(this);
        this.submitArchivedArticle = this.submitArchivedArticle.bind(this);
    }

    componentDidMount() {
        this.refreshMenu();
    }

    getCleanMenuChangesState() {
        return {
            menuElementForm: {open: false, element: null},
            menuImageForm: {elements: [], page: 1},
            menuMoveForm: {element: null},
            menuDeleteForm: {element: null},
            storageUploadForm: {open: false},
            storageImageForm: {elements: [], page: 1},
            storageFileForm: {elements: [], page: 1},
            archivedArticlesForm: {elements: [], page: 1},
            info: {text: ''},
            progress: null
        };
    }

    closeMenuChanges() {
        this.setState(this.getCleanMenuChangesState());
    }

    closeInfo() {
        this.setState({info: {text: ''}});
    }

    showProgress(percent) {
        if (percent <= 100) {
            this.setState({progress: percent});
        } else {
            this.setState({progress: null});
        }
    }

    cancelProgress() {
        this.props.apiClient.cancelRequests();
        this.setState({progress: null});
    }

    refreshMenu() {
        this.props.apiClient.callApi('getMenu', {}).then(
            response => {
                let images_ids = [];
                let menuElements = response.data.results;
                menuElements.forEach((element, index) => {
                    try {
                        const content = JSON.parse(element.content);
                        if (element.kind === 'image') {
                            images_ids.push(content.id);
                        }
                        menuElements[index].content = content;
                    } catch (error) {
                        console.log(error);
                    }
                });
                images_ids = [...new Set(images_ids)];
                if (images_ids.length) {
                    this.props.apiClient.callApi('getMedia', {ids: images_ids}).then(
                        response => {
                            if (response.data.results) {
                                let imagesMap = new Map();
                                response.data.results.forEach(element => {
                                    imagesMap.set(element.id, element);
                                });
                                menuElements.forEach((element, index) => {
                                    if (element.kind === 'image') {
                                        const imageData = imagesMap.get(element.content.id);
                                        if (imageData === undefined) {
                                            menuElements[index].content = {};
                                        } else {
                                            menuElements[index].content = {...imageData};
                                        }
                                    }
                                });
                                this.setState({...{menuElements: menuElements}, ...this.getCleanMenuChangesState()});
                                this.props.updateRoutes(this.getRoutes(menuElements));
                            }
                        }
                    ).catch(
                        error => {
                            console.log(error);
                            menuElements.forEach((element, index) => {
                                if (element.kind === 'image') {
                                    menuElements[index].content = {};
                                }
                            });
                            this.setState({...{menuElements: menuElements}, ...this.getCleanMenuChangesState()});
                            this.props.updateRoutes(this.getRoutes(menuElements));
                        }
                    );
                } else {
                    this.setState({...{menuElements: menuElements}, ...this.getCleanMenuChangesState()});
                    this.props.updateRoutes(this.getRoutes(menuElements));
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }

    getRoutes(menuElements) {
        let routes = [];
        menuElements.forEach(element => {
            if (element.kind === 'link' && element.slug !== undefined) {
                routes.push(element.slug);
            }
        });

        return routes;
    }

    findMenuElementById(id) {
        let selectedElement = null;
        this.state.menuElements.forEach(element => {
            if (element.id === id) {
                selectedElement = element;
            }
        });

        return selectedElement;
    }

    openMenuElementForm(id) {
        this.setState({menuElementForm: {open: true, element: this.findMenuElementById(id)}});
    }

    openMenuImageForm(page) {
        this.props.apiClient.callApi('getImages', {page: page}).then(
            response => {
                if (response.data.results.length) {
                    this.setState({menuImageForm: {elements: response.data.results, page: page}});
                } else {
                    this.setState({info: {text: 'No more images in storage'}});
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }

    submitMenuElementOrImage(values) {
        let params = {
            content: {}
        }
        let kind = '';
        let method = '';
        if (this.state.menuElementForm.element === null) {
            kind = values.kind;
            method = 'addMenuElement';
            params.kind = values.kind;
            params.slug = '';
            let max_right = 0;
            if (this.state.menuElements.length > 0) {
                this.state.menuElements.forEach(element => {
                    if (element.right > max_right) {
                        max_right = element.right;
                    }
                });
            }
            params.left = params.right = max_right + 1;
        } else {
            kind = this.state.menuElementForm.element.kind;
            method = 'editMenuElement';
            params.id = this.state.menuElementForm.element.id;
        }
        if (kind === 'image' && parseInt(values.id) > 0) {
            params.content.id = parseInt(values.id);
        } else if (kind === 'link' && values.title.length > 3) {
            params.content.title = values.title;
            if (this.state.menuElementForm.element === null) {
                params.slug = '/' + params.content.title.toLowerCase().replace(/[^a-z0-9]/ig, '');
            }
        } else if (kind === 'dropdown' && values.title.length > 3) {
            params.content.title = values.title;
            params.right = params.left + 1;
        }
        params.content = JSON.stringify(params.content);
        if (params.content === '{}') {
            params.content = null;
        }
        this.props.apiClient.callApi(method, params).then(
            response => {
                this.refreshMenu();
            }
        ).catch(
            error => {
                console.log(error);
                this.setState({info: {text: 'Invalid values provided'}});
            }
        );
    }

    openMenuMoveForm(id) {
        this.setState({menuMoveForm: {element: this.findMenuElementById(id)}});
    }

    handleMenuDragging(id) {
        let is_before = false;
        let is_after = false;
        if (String(id).startsWith('before_')) {
            id = parseInt(id.replace('before_', ''));
            is_before = true;
        } else if (String(id).startsWith('after_')) {
            id = parseInt(id.replace('after_', ''));
            is_after = true;
        }
        const hoveredElement = this.findMenuElementById(id);
        const movingElement = this.state.menuMoveForm.element;
        if (hoveredElement.id === movingElement.id && !is_before && !is_after) {
            return this.state.menuElements;
        }
        if (
            hoveredElement.id === movingElement.id
            || (hoveredElement.left > movingElement.left && hoveredElement.right < movingElement.right && !is_before && !is_after)
            || (movingElement.right < hoveredElement.left && is_before)
            || (movingElement.left > hoveredElement.right && is_after)
        ) {
            return this.state.menuElements;
        }
        let updatedMenuElements = [];
        let updatedMovingElement = null;
        this.state.menuElements.forEach(element => {
            let currentElement = {
                ...element,
                ...{
                    left: this.getPositionForElement(element.left, movingElement, hoveredElement, is_before, is_after),
                    right: this.getPositionForElement(element.right, movingElement, hoveredElement, is_before, is_after)
                }
            };
            updatedMenuElements.push(currentElement);
            if (element.id === movingElement.id) {
                updatedMovingElement = {...currentElement};
            }
        });
        updatedMenuElements.sort((a, b) => (a.left > b.left) ? 1 : -1);
        this.setState({menuElements: updatedMenuElements, menuMoveForm: {element: updatedMovingElement}});

        return updatedMenuElements;
    }

    getPositionForElement(currentPosition, movingElement, hoveredElement, is_before, is_after) {
        if (movingElement.left > hoveredElement.left) {
            if (hoveredElement.kind === 'dropdown' && !is_before && !is_after) {
                if (currentPosition <= hoveredElement.left || currentPosition > movingElement.right) {
                    return currentPosition;
                } else if (currentPosition > hoveredElement.left && currentPosition < movingElement.left) {
                    return currentPosition + (movingElement.right - movingElement.left + 1);
                } else if (currentPosition >= movingElement.left && currentPosition <= movingElement.right) {
                    return currentPosition - ((movingElement.left - 1) - hoveredElement.left);
                }
            } else if (hoveredElement.kind === 'dropdown' && movingElement.left < hoveredElement.right && is_after) {
                if (currentPosition < movingElement.left || currentPosition > hoveredElement.right) {
                    return currentPosition;
                } else if (currentPosition >= movingElement.left && currentPosition <= movingElement.right) {
                    return currentPosition + (hoveredElement.right - (movingElement.right + 1) + 1);
                } else if (currentPosition > movingElement.right && currentPosition <= hoveredElement.right) {
                    return currentPosition - (movingElement.right - movingElement.left + 1);
                }
            } else {
                if (currentPosition < hoveredElement.left || currentPosition > movingElement.right) {
                    return currentPosition;
                } else if (currentPosition >= hoveredElement.left && currentPosition < movingElement.left) {
                    return currentPosition + (movingElement.right - movingElement.left + 1);
                } else if (currentPosition >= movingElement.left && currentPosition <= movingElement.right) {
                    return currentPosition - ((movingElement.left - 1) - hoveredElement.left + 1);
                }
            }
        } else if (movingElement.left < hoveredElement.left) {
            if (hoveredElement.kind === 'dropdown' && !is_before && !is_after) {
                if (currentPosition < movingElement.left || currentPosition > hoveredElement.left) {
                    return currentPosition;
                } else if (currentPosition >= movingElement.left && currentPosition <= movingElement.right) {
                    return currentPosition + ((hoveredElement.left - 1) - (movingElement.right + 1) + 1 + 1);
                } else if (currentPosition > movingElement.right && currentPosition <= hoveredElement.left) {
                    return currentPosition - (movingElement.right - movingElement.left + 1);
                }
            } else {
                if (currentPosition < movingElement.left || currentPosition > hoveredElement.right) {
                    return currentPosition;
                } else if (currentPosition >= movingElement.left && currentPosition <= movingElement.right) {
                    return currentPosition + (hoveredElement.right - (movingElement.right + 1) + 1);
                } else if (currentPosition > movingElement.right && currentPosition <= hoveredElement.right) {
                    return currentPosition - (movingElement.right - movingElement.left + 1);
                }
            }
        }
    }

    submitMenuMove(id) {
        const updatedMenuElements = this.handleMenuDragging(id);
        let params = [];
        updatedMenuElements.forEach(element => {
            params.push({id: element.id, left: element.left, right: element.right});
        });
        this.props.apiClient.callApi('changeMenuOrder', params).then(
            response => {
                this.refreshMenu();
            }
        ).catch(
            error => {
                console.log(error);
                this.refreshMenu();
            }
        );
    }

    openMenuDeleteForm(id) {
        this.setState({menuDeleteForm: {element: this.findMenuElementById(id)}});
    }

    submitMenuDelete() {
        if (this.state.menuDeleteForm.element === null) {
            this.closeMenuChanges();
        }
        this.props.apiClient.callApi('deleteMenuElement', {id: this.state.menuDeleteForm.element.id}).then(
            response => {
                this.refreshMenu();
            }
        ).catch(
            error => {
                console.log(error);
                this.refreshMenu();
            }
        );
    }

    openStorageUploadForm() {
        this.setState({storageUploadForm: {open: true}});
    }

    uploadFile(values) {
        this.props.apiClient.callApi('uploadMedia', {
            title: values.title,
            content: values.file,
            progressCallback: (progress) => {
                this.showProgress(Math.round((progress.loaded * 100) / progress.total));
            }
        }).then(
            response => {
                this.closeMenuChanges();
                this.setState({info: {text: 'File uploaded'}});
            }
        ).catch(
            error => {
                console.log(error);
                this.setState({info: {text: 'Could not upload file'}, progress: null});
            }
        );
    }

    openStorageImageForm(page) {
        this.props.apiClient.callApi('getImages', {page: page}).then(
            response => {
                if (response.data.results.length) {
                    this.setState({storageImageForm: {elements: response.data.results, page: page}});
                } else {
                    this.setState({info: {text: 'No more images in storage'}});
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }

    openStorageFileForm(page) {
        this.props.apiClient.callApi('getFiles', {page: page}).then(
            response => {
                if (response.data.results.length) {
                    this.setState({storageFileForm: {elements: response.data.results, page: page}});
                } else {
                    this.setState({info: {text: 'No more files in storage'}});
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }

    submitStorage(ids) {
        if (ids.length < 1) {
            this.closeMenuChanges();
            return;
        }
        this.props.apiClient.callApi('deleteMedia', {ids: ids}).then(
            response => {
                this.setState({...this.getCleanMenuChangesState(), ...{info: {text: 'Deleted selected items'}}});
            }
        ).catch(
            error => {
                console.log(error);
                this.setState({info: {text: 'Could not delete selected items'}});
            }
        );
    }

    openArchivedArticlesForm(page) {
        this.props.apiClient.callApi('getArticlesWithNoMenu', {page: page}).then(
            response => {
                if (response.data.results.length) {
                    let slugs = []
                    response.data.results.forEach(element => {
                        slugs.push(element.slug);
                    });
                    this.setState({archivedArticlesForm: {elements: slugs, page: page}});
                } else {
                    this.setState({info: {text: 'No more archived articles in storage'}});
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }

    submitArchivedArticle(slugs_to_delete, slugs_to_restore) {
        if (slugs_to_delete.length < 1 && slugs_to_restore.length < 1) {
            this.closeMenuChanges();
            return;
        }
        if (slugs_to_delete.length > 0) {
            this.props.apiClient.callApi('deleteArticleElements', slugs_to_delete).then(
                response => {
                    this.setState({...this.getCleanMenuChangesState(), ...{info: {text: 'Deleted selected archived articles'}}});
                }
            ).catch(
                error => {
                    console.log(error);
                    this.setState({info: {text: 'Could not delete selected archived articles'}});
                }
            );
        }
        if (slugs_to_restore.length > 0) {
            this.props.apiClient.callApi('restoreMenuElements', slugs_to_restore).then(
                response => {
                    this.refreshMenu();
                }
            ).catch(
                error => {
                    console.log(error);
                    this.setState({info: {text: 'Could not restore selected archived articles'}});
                }
            );
        }
    }

    render() {
        let buttons = [];
        let form = null;
        let info = this.state.info.text !== ''? <Info description={this.state.info.text} handleOk={this.closeInfo} /> : null;
        let progress = this.state.progress !== null ? <Progress progress={this.state.progress} handleCancel={this.cancelProgress} /> : null;
        if (this.props.loggedIn) {
            buttons.push(<Button key='add-element' className='w-100 my-1' variant='success' onClick={this.openMenuElementForm}>Add menu link or dropdown</Button>);
            buttons.push(<Button key='add-image' className='w-100 my-1' variant='success' onClick={e => this.openMenuImageForm(1)}>Add menu image</Button>);
            buttons.push(<Button key='storage-upload' className='w-100 my-1' variant='success' onClick={this.openStorageUploadForm}>Upload files or images</Button>);
            buttons.push(<Button key='storage-images' className='w-100 my-1' variant='success' onClick={e => this.openStorageImageForm(1)}>View storage images</Button>);
            buttons.push(<Button key='storage-files' className='w-100 my-1' variant='success' onClick={e => this.openStorageFileForm(1)}>View storage files</Button>);
            buttons.push(<Button key='archived-articles' className='w-100 my-1' variant='success' onClick={e => this.openArchivedArticlesForm(1)}>View archived articles</Button>);
            if (this.state.menuElementForm.open && this.state.menuElementForm.element === null) {
                form = <MenuAddElementForm handleSubmit={this.submitMenuElementOrImage} handleCancel={this.closeMenuChanges} />;
            } else if (this.state.menuElementForm.open && this.state.menuElementForm.element !== null) {
                form = (
                    <MenuEditElementForm
                        handleSubmit={this.submitMenuElementOrImage}
                        handleCancel={this.closeMenuChanges}
                        menuElement={this.state.menuElementForm.element}
                    />
                );
            } else if (this.state.menuImageForm.elements.length) {
                form = (
                    <MenuAddImageForm
                        handleSubmit={this.submitMenuElementOrImage}
                        handleCancel={this.closeMenuChanges}
                        handlePagination={this.openMenuImageForm}
                        currentPage={this.state.menuImageForm.page}
                        elements={this.state.menuImageForm.elements}
                    />
                );
            } else if (this.state.menuDeleteForm.element !== null) {
                form = (
                    <Confirmation
                        handleYes={this.submitMenuDelete}
                        handleNo={this.closeMenuChanges}
                        text='Are you sure you want to delete this menu element? If this menu element is a link, then all related article content will go to archived articles.'
                    />
                );
            } else if (this.state.storageUploadForm.open) {
                form = <StorageUploadForm handleSubmit={this.uploadFile} handleCancel={this.closeMenuChanges} />;
            } else if (this.state.storageImageForm.elements.length) {
                form = (
                    <StorageImageForm
                        handleSubmit={this.submitStorage}
                        handleCancel={this.closeMenuChanges}
                        handlePagination={this.openStorageImageForm}
                        currentPage={this.state.storageImageForm.page}
                        elements={this.state.storageImageForm.elements}
                    />
                );
            } else if (this.state.storageFileForm.elements.length) {
                form = (
                    <StorageFileForm
                        handleSubmit={this.submitStorage}
                        handleCancel={this.closeMenuChanges}
                        handlePagination={this.openStorageFileForm}
                        currentPage={this.state.storageFileForm.page}
                        elements={this.state.storageFileForm.elements}
                    />
                );
            } else if (this.state.archivedArticlesForm.elements.length) {
                form = (
                    <ArchivedArticlesForm
                        handleSubmit={this.submitArchivedArticle}
                        handleCancel={this.closeMenuChanges}
                        handlePagination={this.openArchivedArticlesForm}
                        currentPage={this.state.archivedArticlesForm.page}
                        elements={this.state.archivedArticlesForm.elements}
                    />
                );
            }
        }

        return (
            <>
                <Navbar expand='md'>
                    <Navbar.Toggle aria-controls='main-menu-navbar-toggle' />
                    <Navbar.Collapse id='main-menu-navbar-toggle'>
                        <Nav className='flex-column w-100 px-0'>
                            <MenuRows
                                elements={this.state.menuElements}
                                elementEdit={this.openMenuElementForm}
                                elementMove={this.openMenuMoveForm}
                                handleDragging={this.handleMenuDragging}
                                submitMove={this.submitMenuMove}
                                elementDelete={this.openMenuDeleteForm}
                                loggedIn={this.props.loggedIn}
                                movingElement={this.state.menuMoveForm.element}
                            />
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {buttons}
                {form}
                {info}
                {progress}
            </>
        );
    }
}

export default Menu;
