import React from 'react';
import MenuLink from './MenuLink.js';
import MenuDropdown from './MenuDropdown.js';
import MenuImage from './MenuImage.js';

class MenuRows extends React.Component {
    render () {
        let current_dropdown_values = {'left': 0, 'right': 0, 'elements': [], 'nested_positions_map': new Map()};
        let elements = [];

        this.props.elements.forEach(element => {
            if (current_dropdown_values.right !== 0) {
                if (current_dropdown_values.right <= element.left) {
                    elements.push(
                        <MenuDropdown
                            key={current_dropdown_values.id}
                            title={current_dropdown_values.title}
                            elements={current_dropdown_values.elements}
                            left={current_dropdown_values.left}
                            right={current_dropdown_values.right}
                            loggedIn={this.props.loggedIn}
                            elementEdit={this.props.elementEdit}
                            elementMove={this.props.elementMove}
                            movingElement={this.props.movingElement}
                            handleDragging={this.props.handleDragging}
                            submitMove={this.props.submitMove}
                            elementDelete={this.props.elementDelete}
                            elementId={current_dropdown_values.id}
                        />
                    );
                    current_dropdown_values = {'left': 0, 'right': 0, 'elements': [], 'nested_positions_map': new Map()};
                } else {
                    current_dropdown_values.elements.push(element);
                    current_dropdown_values.nested_positions_map.delete(element.left);
                    current_dropdown_values.nested_positions_map.delete(element.right);
                }
            }

            if (current_dropdown_values.right === 0) {
                switch (element.kind) {
                    case 'link':
                        elements.push(
                            <MenuLink
                                key={element.id}
                                title={element.content.title}
                                slug={element.slug}
                                left={element.left}
                                right={element.right}
                                loggedIn={this.props.loggedIn}
                                elementEdit={this.props.elementEdit}
                                elementMove={this.props.elementMove}
                                movingElement={this.props.movingElement}
                                handleDragging={this.props.handleDragging}
                                submitMove={this.props.submitMove}
                                elementDelete={this.props.elementDelete}
                                elementId={element.id}
                            />
                        );
                        break;
                    case 'image':
                        elements.push(
                            <MenuImage
                                key={element.id}
                                content={element.content}
                                left={element.left}
                                right={element.right}
                                loggedIn={this.props.loggedIn}
                                elementEdit={this.props.elementEdit}
                                elementMove={this.props.elementMove}
                                movingElement={this.props.movingElement}
                                handleDragging={this.props.handleDragging}
                                submitMove={this.props.submitMove}
                                elementDelete={this.props.elementDelete}
                                elementId={element.id}
                            />
                        );
                        break;
                    case 'dropdown':
                        current_dropdown_values = {
                            'id': element.id,
                            'title': element.content.title,
                            'left': element.left,
                            'right': element.right,
                            'nested_positions_map': new Map(),
                            'elements': []
                        };
                        let left_right = current_dropdown_values.right - 1;
                        while (left_right > current_dropdown_values.left) {
                            current_dropdown_values.nested_positions_map.set(left_right, true);
                            left_right --;
                        };
                        break;
                    default:
                        break;
                }
            }
        });

        if (current_dropdown_values.right !== 0) {
            elements.push(
                <MenuDropdown
                    key={current_dropdown_values.id}
                    title={current_dropdown_values.title}
                    elements={current_dropdown_values.elements}
                    left={current_dropdown_values.left}
                    right={current_dropdown_values.right}
                    loggedIn={this.props.loggedIn}
                    elementEdit={this.props.elementEdit}
                    elementMove={this.props.elementMove}
                    movingElement={this.props.movingElement}
                    handleDragging={this.props.handleDragging}
                    submitMove={this.props.submitMove}
                    elementDelete={this.props.elementDelete}
                    elementId={current_dropdown_values.id}
                />
            );
        };

        return(
            <>
                {elements}
            </>
        );
    }
}

export default MenuRows;
