import React from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import './ArticleImageViewForm.scss';

class ArticleImageViewForm extends React.Component
{
    constructor(props) {
        super(props);
        this.previousImage = this.previousImage.bind(this);
        this.nextImage = this.nextImage.bind(this);
        this.hideImage = this.hideImage.bind(this);
    }

    previousImage() {
        this.props.changeImage(this.props.element.id, -1);
    }

    nextImage() {
        this.props.changeImage(this.props.element.id, 1);
    }

    hideImage() {
        this.props.handleCancel();
    }

    render() {
        return (
            <Modal
                show={true}
                size='xl'
                aria-labelledby='contained-modal-title-vcenter'
                backdrop='static'
                centered
                onHide={this.hideImage}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center'>
                    <Button className='pr-0 b-0 modal-image-arrow' variant='transparent' onClick={this.previousImage}>&#10216;</Button>
                    <div className='col-11'>
                        <Image className='w-100' src={this.props.element.content.slug} alt={this.props.element.content.title} title={this.props.element.content.title} />
                    </div>
                    <Button className='pl-0 b-0 modal-image-arrow' variant='transparent' onClick={this.nextImage}>&#10217;</Button>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ArticleImageViewForm;
