import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ModalFormWrapper from './ModalFormWrapper.js';
import Image from 'react-bootstrap/Image';
import './ArticleImageForm.scss';

class ArticleImageForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getFormElementsIdKeyed();
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePaginationPrevious = this.handlePaginationPrevious.bind(this);
        this.handlePaginationNext = this.handlePaginationNext.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentPage !== this.props.currentPage) {
            this.setState(this.getFormElementsIdKeyed());
        }
    }

    getFormElementsIdKeyed() {
        let elements = {}
        this.props.elements.forEach(element => {
            elements[element.id] = {...element, ...{marked_for_add: false}};
        });

        return {elements: elements};
    }

    handleChange(e) {
        if (String(e.target.name).startsWith('add_')) {
            const id = e.target.name.replace('add_', '');
            this.setState(prevState => ({elements: {...prevState.elements, ...{[id]: {...prevState.elements[id], ...{marked_for_add: e.target.checked}}}}}));
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        let ids_to_add = [];
        Object.entries(this.state.elements).forEach(([id, element]) => {
            if (element.marked_for_add) {
                ids_to_add.push(element.id);
            }
        });
        this.props.handleSubmit({kind: 'image', ids: ids_to_add});
    }

    handleCancel(e) {
        this.props.handleCancel();
    }

    handlePaginationNext() {
        this.props.handlePagination(this.props.currentPage + 1)
    }

    handlePaginationPrevious() {
        if (1 < this.props.currentPage) {
            this.props.handlePagination(this.props.currentPage - 1)
        }
    }

    render() {
        const fields = Object.entries(this.state.elements).map(([id, element]) => {
            return (
                <div key={'add_' + element.id} className='image-checkbox-container'>
                    <Image src={element.slug} alt={element.title} title={element.title} />
                    <Form.Check
                        inline
                        className='image-checkbox'
                        checked={this.state.elements[element.id].marked_for_add}
                        onChange={this.handleChange}
                        label=' '
                        name={'add_' + element.id}
                        id={'add_' + element.id}
                    />
                </div>
            );
        });

        return (
            <ModalFormWrapper
                handleCancel={this.handleCancel}
                handleSubmit={this.handleSubmit}
                title='Add images to article'
                content={
                    <>
                        <div className='images-list-container'>
                            {fields}
                        </div>
                    </>
                }
                buttons={
                    <>
                        <Button variant='secondary' disabled>Current page {this.props.currentPage}</Button>
                        <div>
                            <Button className='m-1' variant='success' onClick={this.handlePaginationPrevious}>Previous page</Button>
                            <Button className='m-1' variant='success' onClick={this.handlePaginationNext}>Next page</Button>
                        </div>
                        <div>
                            <Button className='m-1' variant='danger' onClick={this.handleCancel}>Cancel</Button>
                            <Button className='m-1' type='submit' variant='success'>Add selected</Button>
                        </div>
                    </>
                }
            />
        );
    }
}

export default ArticleImageForm;
