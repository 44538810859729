import axios from 'axios';

class ApiClient {
    constructor(apiUrl, apiResourceOwner) {
        this.apiUrl = apiUrl;
        this.apiResourceOwner = apiResourceOwner;
        this.config = {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        };
        this.cancelTokenSource = axios.CancelToken.source();
    }

    updateCsrfToken(token) {
        if (token.length) {
            this.config.headers['X-CsrfToken'] = token;
        } else {
            delete this.config.headers['X-CsrfToken'];
        }
    }

    cancelRequests() {
        this.cancelTokenSource.cancel('Operation canceled by the user');
        this.cancelTokenSource = axios.CancelToken.source();
    }

    async callApi(method, parameters) {
        switch (method) {
            case 'getMenu':
                return axios({
                    method: 'get',
                    url: this.apiUrl + '/menu/?owner_id=' + parseInt(this.apiResourceOwner)
                 });
            case 'getArticle':
                return axios({
                    method: 'get',
                    url: this.apiUrl + '/article/?slug=' + encodeURIComponent(parameters.slug) + '&owner_id=' + parseInt(this.apiResourceOwner)
                 });
            case 'getMedia':
                let getMediaQueryString = '';
                parameters.ids.forEach(id => {
                    getMediaQueryString += '&ids[]=' + parseInt(id);
                });
                getMediaQueryString = '?' + getMediaQueryString.substring(1) + '&owner_id=' + parseInt(this.apiResourceOwner);

                return axios({
                    method: 'get',
                    url: this.apiUrl + '/media/' + getMediaQueryString
                });
            case 'getSession':
                return axios({...{
                    method: 'get',
                    url: this.apiUrl + '/auth/session/'
                }, ...this.config});
            case 'getCsrfToken':
                return axios({...{
                    method: 'get',
                    url: this.apiUrl + '/auth/csrf/'
                }, ...this.config});
            case 'loginUser':
                return axios({...{
                    method: 'post',
                    url: this.apiUrl + '/auth/login/',
                    data: {username: parameters.username, password: parameters.password, owner_id: parseInt(this.apiResourceOwner)},
                }, ...this.config});
            case 'logoutUser':
                return axios({...{
                    method: 'get',
                    url: this.apiUrl + '/auth/logout/'
                }, ...this.config});
            case 'addMenuElement':
                return axios({...{
                    method: 'post',
                    url: this.apiUrl + '/menu/',
                    data: parameters
                }, ...this.config});
            case 'editMenuElement':
                return axios({...{
                    method: 'patch',
                    url: this.apiUrl + '/menu/' + parameters.id + '/',
                    data: {content: parameters.content},
                }, ...this.config});
            case 'deleteMenuElement':
                return axios({...{
                    method: 'delete',
                    url: this.apiUrl + '/menu/' + parameters.id + '/'
                }, ...this.config});
            case 'changeMenuOrder':
                return axios({...{
                    method: 'patch',
                    url: this.apiUrl + '/menu/change_order/',
                    data: parameters
                }, ...this.config});
            case 'uploadMedia':
                const uploadFormData = new FormData();
                let uploadConfig = JSON.parse(JSON.stringify(this.config));
                uploadConfig.headers['Content-Type'] = 'multipart/form-data';
                uploadConfig.cancelToken = this.cancelTokenSource.token;
                if ('progressCallback' in parameters && 'function' === typeof parameters.progressCallback) {
                    uploadConfig.onUploadProgress = parameters.progressCallback;
                }
                uploadFormData.append('title', parameters.title);
                uploadFormData.append('content', parameters.content);

                return axios({...{
                    method: 'post',
                    url: this.apiUrl + '/media/',
                    data: uploadFormData,
                }, ...uploadConfig});
            case 'getImages':
                const imageLimit = 12;
                const imageOffset = (parameters.page - 1) * imageLimit;
                const imageQueryString = '?kind=image&limit=' + parseInt(imageLimit) + '&offset=' + parseInt(imageOffset) + '&owner_id=' + parseInt(this.apiResourceOwner);

                return axios({
                    method: 'get',
                    url: this.apiUrl + '/media/' + imageQueryString
                });
            case 'getFiles':
                const fileLimit = 12;
                const fileOffset = (parameters.page - 1) * fileLimit;
                const fileQueryString = '?kind=file&limit=' + parseInt(fileLimit) + '&offset=' + parseInt(fileOffset) + '&owner_id=' + parseInt(this.apiResourceOwner);

                return axios({
                    method: 'get',
                    url: this.apiUrl + '/media/' + fileQueryString
                });
            case 'deleteMedia':
                return axios({...{
                    method: 'patch',
                    url: this.apiUrl + '/media/bulk_delete/',
                    data: parameters
                }, ...this.config});
            case 'getArticlesWithNoMenu':
                const archivedArticlesLimit = 20;
                const archivedArticlesOffset = (parameters.page - 1) * archivedArticlesLimit;
                const archivedArticlesQueryString = '?no_menu=1&limit=' + parseInt(archivedArticlesLimit) + '&offset=' + parseInt(archivedArticlesOffset) + '&owner_id=' + parseInt(this.apiResourceOwner);

                return axios({...{
                    method: 'get',
                    url: this.apiUrl + '/article/' + archivedArticlesQueryString
                }, ...this.config});
            case 'restoreMenuElements':
                return axios({...{
                    method: 'patch',
                    url: this.apiUrl + '/menu/bulk_create/',
                    data: parameters
                }, ...this.config});
            case 'deleteArticleElements':
                return axios({...{
                    method: 'patch',
                    url: this.apiUrl + '/article/bulk_delete/',
                    data: parameters
                }, ...this.config});
            case 'addArticleElement':
                return axios({...{
                    method: 'post',
                    url: this.apiUrl + '/article/',
                    data: parameters
                }, ...this.config});
            case 'editArticleElement':
                return axios({...{
                    method: 'patch',
                    url: this.apiUrl + '/article/' + parameters.id + '/',
                    data: {content: parameters.content},
                }, ...this.config});
            case 'addArticleMedia':
                return axios({...{
                    method: 'patch',
                    url: this.apiUrl + '/article/bulk_create/',
                    data: parameters
                }, ...this.config});
            case 'changeArticleOrder':
                return axios({...{
                    method: 'patch',
                    url: this.apiUrl + '/article/change_order/',
                    data: parameters
                }, ...this.config});
            case 'deleteArticleElement':
                return axios({...{
                    method: 'delete',
                    url: this.apiUrl + '/article/' + parameters.id + '/'
                }, ...this.config});
            default:
                return await Promise.reject('Client method does not exist');
        }
    }
}

export default ApiClient;
