import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class Info extends React.Component
{
    render() {
        return (
            <Modal
                show={true}
                onHide={this.props.handleOk}
                backdrop='static'
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.description}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={this.props.handleOk}>Ok</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Info;
